@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


@layer base {
    body {
        font-family: 'Inter', sans-serif;
        background-color: #0a0a0a;
        overflow-x: hidden;
    }
    ::selection {
        background: #a3a3a3;
        color:#1c1917 ;
    }
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: #0c0c0c;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #949494;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #a6a6a6;
    }
  }
